import React, { Fragment } from 'react';
import * as queryString from "query-string";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/theme/app';
import { AppWrapper, GlobalStyle } from 'containers/App/app.style';
import ResultSection from 'containers/App/ResultSection';
import { ResetCSS } from 'common/assets/css/style';
import CircularProgressOverlay from 'common/mycomponents/CircularProgressOverlay';

const LoginResultPage = ({ location }) => {
  const { type } = queryString.parse(location.search);

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        <CircularProgressOverlay>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <ResultSection type={type} />
            </Sticky>
          </AppWrapper>
        </CircularProgressOverlay>
      </Fragment>
    </ThemeProvider>
  );
};

export default LoginResultPage;